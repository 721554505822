



















import { Component, Vue, Prop } from 'vue-property-decorator';
import ApexChart from 'vue-apexcharts';
import { chartBarValue, chartBarValues, ITextMetric } from '@/types/metric';
import TimestampButtons from '@/components/visualization-metric/TimestampButtons.vue';
import { ITimestamp } from '@/types/common';
import { store } from '@/store';

@Component({ name: 'BarChart', components: { TimestampButtons, ApexChart } })
export default class BarChart extends Vue {
  @Prop() private readonly metricId!: number;
  @Prop() private readonly data!: chartBarValues;
  @Prop() protected readonly text!: ITextMetric;
  private xNames: Array<string> = [];
  private yValues: Array<number> = [];
  private colors: Array<string> = [];
  private backgrounsColors: Array<string> = [];
  private timestamps: Array<ITimestamp> = [];

  get series() {
    return [
      {
        name: 'Вероятность',
        data: this.yValues,
      },
    ];
  }
  get titleText() {
    let titleText = '';
    Object.values(this.text).forEach(({ title, value, unit }) => {
      if (typeof value === 'number') value = value.toFixed(1);
      titleText += `${title}: ${value} ${unit}<br>`;
    });
    return titleText;
  }

  get chartOptions() {
    return {
      theme: { mode: 'dark' },
      chart: {
        height: 400,
        type: 'bar',
        background: 'transparent',
        events: {
          click: (event: any, chartContext: any, config: any) => {
            const indexTarget = config.dataPointIndex;
            if (indexTarget !== -1) this.$emit('rewind-to-time', this.$timeToSeconds(this.xNames[indexTarget]));
          },
          mouseMove: (event: any, chartContext: any, config: any) => {
            if (config.dataPointIndex !== -1) event.target.style.cursor = 'pointer';
          },
        },
      },
      colors: this.colors,
      plotOptions: {
        bar: {
          columnWidth: '75%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        borderColor: '#454545 ',
      },
      yaxis: {
        max: 100,
        labels: {
          formatter: (val : any) => `${val} %`,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.xNames,
        tickPlacement: 'on',
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      tooltip: {
        y: {
          formatter: (val: any) => `${val}%`,
        },
      },
    };
  }

  prepareData() {
    this.data.forEach(({ time, proba }: chartBarValue) => {
      const name = this.$secondsToTime(time);
      const color = this.$gradientColor(proba);

      this.xNames.push(name);
      this.yValues.push(proba);
      this.colors.push(color);
      this.backgrounsColors.push(this.$gradientColor(proba, 0.6));

      this.timestamps.push({ from: time, color, label: `${name} - ${proba}%` });
    });
    store.player.mutations.saveIntervals({ intervals: this.timestamps, merticId: this.metricId });
  }

  mounted() {
    this.prepareData();
  }
}
